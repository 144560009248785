import styles from './Animation.module.scss';
import { useVisitPhoneData } from '#store/visitPhoneData';
import Typography from '#components/ui/Typography';
import Button from '#components/ui/Button';
import { FC } from 'react';
import { prepareUrl } from '#utils/prepareUrl';
import i18next from 'i18next';

type Props = {
  onNextStep: () => void;
  trans: {
    finalTitle: string;
    gpsLocation: string;
    createAccount: string;
    button: string;
  };
};

const FinishedAnimationBlock: FC<Props> = ({ trans }) => {
  const { number } = useVisitPhoneData();
  const lang = i18next.language;
  const preparedLink = prepareUrl('/email', lang);
  const handleClick = (preparedLink: string) => {
    window.location.assign(preparedLink);
  };

  return (
    <div className={styles.finishedBg}>
      <div className={styles.finishedCircle}>
        <Typography className={styles.finishedTitle} component="p" variant="h6" color="salad">
          {trans.finalTitle}
        </Typography>
        <Typography className={styles.finishedNumber} component="p" variant="h6" color="primary">
          {number}
        </Typography>
        <Typography
          className={styles.finishedGpsLocation}
          component="p"
          variant="capture"
          color="salad"
        >
          {trans.gpsLocation}
        </Typography>
        <Typography
          className={styles.finishedCreateAccount}
          component="p"
          variant="capture"
          color="lightgray"
        >
          {trans.createAccount}
        </Typography>
        <Button
          text={trans.button}
          styleType="orange"
          customStyle="finishedButton"
          eventName="Locate Result"
          onClick={() => handleClick(preparedLink)}
        />
      </div>
    </div>
  );
};

export default FinishedAnimationBlock;
