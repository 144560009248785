import { FC, useEffect, useState } from 'react';
import { AstroImage } from '#types/AstroImage';
import { useBodyScrollController } from '#hooks/useBodyScrollController';
import {
  useFindLostPhoneAnimationFlag,
  useLocationByNumberAnimationFlag,
} from '#store/initAnimation';
import Animation from './Animation';
import FinishedAnimationBlock from './FinishedAnimationBlock';
import styles from './Animation.module.scss';
import { prepareUrl } from '#utils/prepareUrl';
import analytics from '#utils/analytics';
import { pageReload } from '#utils/pageReload';

type Props = {
  image: AstroImage;
  spinner: AstroImage;
  locale: string;
  feature: string;
  trans: {
    animation: {
      title: string;
      defined: string;
      item1: string;
      item2: string;
      item3: string;
      item4: string;
      item5: string;
    };
    finishedAnimationBlock: {
      finalTitle: string;
      gpsLocation: string;
      createAccount: string;
      button: string;
    };
  };
};

const FunnelAnimation: FC<Props> = ({ trans, locale, feature, ...props }) => {
  const isMainAnimationActivated = useLocationByNumberAnimationFlag();
  const isFindLostPhoneAnimationActivated = useFindLostPhoneAnimationFlag();
  const scroll = useBodyScrollController();
  const [finished, setFinished] = useState<boolean>(false);
  const isMainPage = feature.includes('mainPage');
  const [isExcludedCountry, setIsExcludedCountry] = useState<boolean>(false);
  const excludedCountries = ['BR', 'CL'];
  const skipSecondStep = !isExcludedCountry && isMainPage && finished;

  useEffect(() => {
    (isMainAnimationActivated || isFindLostPhoneAnimationActivated) && scroll.disable();
  }, [isMainAnimationActivated, isFindLostPhoneAnimationActivated]);

  useEffect(() => {
    pageReload();
  }, []);

  useEffect(() => {
    const currentCountry = localStorage.getItem('country') || '';
    setIsExcludedCountry(excludedCountries.includes(currentCountry) && isMainPage);
    if (skipSecondStep) {
      onNextStep();
    }
  }, [finished]);

  if (!isFindLostPhoneAnimationActivated && !isMainAnimationActivated) return '';

  const onNextStep = () => {
    scroll.enable();
    analytics.event({ action: 'Locate Result', category: 'Funnel' });
    location.assign(prepareUrl('/email', locale));
  };

  return (
    <div className={styles.container}>
      {!finished && <Animation setFinished={setFinished} trans={trans.animation} {...props} />}
      {finished && (isExcludedCountry || !isMainPage) && (
        <FinishedAnimationBlock onNextStep={onNextStep} trans={trans.finishedAnimationBlock} />
      )}
    </div>
  );
};

export default FunnelAnimation;
