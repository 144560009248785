import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import styles from './Animation.module.scss';
import { AstroImage } from '#types/AstroImage';
import { useVisitPhoneData } from '#store/visitPhoneData';
import Typography from '#components/ui/Typography';
import Check from '#icons/Check';
import TopImg from '#images/main-animation/top.png';
import BottomImg from '#images/main-animation/bottom.png';

type Props = {
  image: AstroImage;
  spinner: AstroImage;
  setFinished: Dispatch<SetStateAction<boolean>>;
  trans: {
    title: string;
    defined: string;
    item1: string;
    item2: string;
    item3: string;
    item4: string;
    item5: string;
  };
};

const Animation: FC<Props> = ({ image, spinner, setFinished, trans }) => {
  const data = useVisitPhoneData();
  const [current, setCurrent] = useState<number>(-1);
  const phone = data?.number ?? sessionStorage.getItem('phoneNumber');
  const rows = [
    {
      label: trans.item1,
      value: data?.carrier || trans.defined,
      blur: !data?.carrier,
      check: false,
    },
    {
      label: trans.item2,
      value: data?.country || trans.defined,
      blur: !data?.country,
      check: false,
    },
    {
      label: trans.item3,
      value: data?.location || trans.defined,
      blur: !data?.location,
      check: false,
    },
    {
      label: trans.item4,
      value: trans.defined,
      blur: false,
      check: true,
    },
    {
      label: trans.item5,
      value: trans.defined,
      blur: false,
      check: true,
    },
  ];

  useEffect(() => {
    const ROW_ANIMATION_TIME = 2000;

    let currIdx = current;

    const interval = setInterval(() => {
      if (currIdx >= rows.length) {
        setFinished(true);
        return clearInterval(interval);
      }
      setCurrent((current) => ++current);
      ++currIdx;
    }, ROW_ANIMATION_TIME);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.animation}>
      <img className={`${styles.imageBg} ${styles.top}`} src={TopImg.src} alt="Top background" />
      <img
        className={`${styles.imageBg} ${styles.bottom}`}
        src={BottomImg.src}
        alt="Bottom background"
      />
      <div className={styles.circle}>
        <img src={image.src} alt="Loader" className={styles.background} />
      </div>
      <div className={styles.content}>
        <Typography className={styles.title} component="p" variant="subtitle2" color="secondary">
          {trans.title}
        </Typography>
        <Typography className={styles.phone} component="p" variant="h6" color="green">
          {phone}
        </Typography>
        <div className={styles.data}>
          {rows.map((row, index) => {
            if (index > current) return null;
            return (
              <div key={row.label} className={styles.row}>
                <Typography
                  className={styles.label}
                  color={index === current ? 'secondary' : 'black'}
                  component="span"
                  variant="body2"
                >
                  {row.label}
                </Typography>
                {index === current ? (
                  <img className={styles.spinner} src={spinner.src} alt="Spinner" />
                ) : (
                  <div className={styles.value}>
                    <Typography
                      className={row.blur ? styles.blur : ''}
                      component="span"
                      variant="subtitle2"
                      color="green"
                    >
                      {row.value}
                    </Typography>
                    {row.check && <Check />}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Animation;
