import { useCallback } from 'react';

export function useBodyScrollController() {
  const enable = useCallback(() => {
    document.body.style.overflow = 'auto';
  }, []);

  const disable = useCallback(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  return { enable, disable };
}
